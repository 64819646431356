export const BASE_URL =  "https://api.supert20.in/api/v1/";
export const STATIC_BASE_URL = "https://api.supert20.in/";

export const REGISTER = "auth/register";
export const LOGIN = "auth/login";
export const LOGOUT = "auth/logout";
export const VERIFY_OTP = "auth/verify-otp";
export const GETPROFILE = "users/profile";
export const UPLOAD_PROFILE= "users/profile/upload";
export const UPDATE_PROFILE = "users/profile";
export const GET_SLOTS = "users/slots/";
export const BOOKING = "users/bookings/";
export const PAYMENT = "users/create-payment/";
export const GET_BOOKINGS = "users/userbookings/";
export const PROFILE = "users/profile/";
export const ADMINDASHBOARD = "admin/dashboard";
export const Current_BOOKINGS = "admin/current-bookings/";
export const ADMINPAYMENTS = "admin/payments/";
export const SEARCHUSER = "admin/searchUser";
export const UPDATEUSER = "admin/updateUser/";
export const ADMINGETSLOTS = "admin/slots/";
export const UPDATESLOTS = "admin//slots/";
export const UPDATESURGEAMOUNT = "admin/surge-amount/";
export const SUPERVIOR_VERIFY_OTP = "auth/supervisor-verify-otp";
export const SUPERVISOR_SLOT_BOOKING =  "supervisor/book-slot/";
export const SUPERVISOR_CREATE_USER = "supervisor/create-user";
export const SUPERVISOR_DASBOARD = "supervisor/dashboard";
export const SUPERVISOR_BOOKING_DETAILS = "admin/supervisor-summary";
export const ACADEMY_REGISTRATION ="users/register-academy"
export const ACADEMY_SESSIONS = "admin/create-academy-session";
export const ACADEMY_SESSIONS_LIST = "admin/get-academy-sessions";
export const ACADEMY_DETAILS = "users/academy-details";
export const ACADEMY_SESSIONS_DETAILS = "users/academy-sessions";
export const ACADEMY_SESSIONS_UPDATE = "admin/update-academy-session";
export const SET_HOLIDAY  = "admin/set-holiday";

export const GET_HOLIDAY = "admin/get-holiday";
export const ACADEMY_BOOKING = "users/academyBooking";
export const ACADEMY_BOOKING_BYUSER = "users/academyBookingByUser";
export const BONUS_SLOT_BOOKING = "users/bonus-slot-booking";
export const ACADEMY_SESSION_BOOKING = "admin/get-academy-bookings";
export const ACADEMY_UPDATE_PROFILE = "users/academy-details";
export const SUPERVISOR_ACADEMY_SESSION_BOOKING = "supervisor/academy-booking";
export const CANCLE_BOOKING = "admin/cancel-booking";
