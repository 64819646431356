import {
    combineReducers
} from "redux";

import registerReducer from '../slices/register';
import loginReducer from '../slices/login';
import logoutReducer from '../slices/logout';
import getProfileDetailsReducer from '../slices/getprofiledetails';
import verifyOtpReducer from '../slices/verifyotp';
import getSlotsReducer from '../slices/getSlots';
import paymentReducer from '../slices/paymentDetails';
import bookingReducer from '../slices/bookingDetails';
import updateProfileReducer from '../slices/updateprofile';
import userBookingsReducer from '../slices/userBookings';
import adminDashboardReducer from '../slices/adminDashboard';
import adminCurrentBookingsReducer from '../slices/adminCurrentBookings';
import AdminPaymentReducer from "../slices/adminPayments";
import searchWithMobileReducer from "../slices/searchWithMobile";
import updateUserReducer from "../slices/updateUser";
import adminSlotsReducer from "../slices/adminGetSlots";
import updateSlotDetailsReducer from "../slices/updateSlotDetails";
import updateSurgeAmountReducer from "../slices/updateSurgeAmount";
import supervisorSlotBookingReducer from "../slices/supervisorSlotBooking";
import supervisorVerifyOtpReducer from "../slices/supervisorVerifyOtp";
import supervisorCreateUserReducer from "../slices/supervisorCreateUser";
import supervisorDashboardReducer from "../slices/supervisorDashboard";
import supervisorBookingDetailsReducer from "../slices/supervisorBookingDetails";
import academyRegistrationReducer from "../slices/academyregistration";
import AcademySessionsReducer from "../slices/academySessions";
import AcademySessionsListReducer from "../slices/getAcademySessions";
import academyDetailsReducer from "../slices/academyDetails";
import academySessionDetailsReducer from "../slices/academySessionDetails";
import academySessionUpdateReducer from "../slices/academySessionUpdate";
import setHolidayReducer from "../slices/setHoliday";
import getHolidayReducer from "../slices/getHoliday";
import bonusSlotBookingReducer from "../slices/bonusSlotBooking";
import academyBookingReducer from "../slices/academyBooking";
import academyBookingByUserReducer from "../slices/academyBookingByUser";
import academySessionBookingReducer from "../slices/academySessionBookings";
import academyUpdateProfileReducer from "../slices/updateAcademyProfile";
import supervisorAcademyBookingReducer from "../slices/supervisorAcademyBooking";
import cancleBookingReducer from "../slices/cancleBooking";
import adminnUserListReducer from "../slices/adminDownlodeUserList";
import upcomingBookingsReducer from "../slices/upcomingBookings";

export default combineReducers({
    registerReducer,
    loginReducer,
    getProfileDetailsReducer,
    verifyOtpReducer,
    getSlotsReducer,
    paymentReducer,
    bookingReducer,
    updateProfileReducer,
    logoutReducer,
    userBookingsReducer,
    adminDashboardReducer,
    adminCurrentBookingsReducer,
    AdminPaymentReducer,
    searchWithMobileReducer,
    updateUserReducer,
    adminSlotsReducer,
    updateSlotDetailsReducer,
    updateSurgeAmountReducer,
    supervisorSlotBookingReducer,
    supervisorVerifyOtpReducer,
    supervisorCreateUserReducer,
    supervisorDashboardReducer,
    supervisorBookingDetailsReducer,
    academyRegistrationReducer,
    AcademySessionsReducer,
    AcademySessionsListReducer,
    academyDetailsReducer,
    academySessionDetailsReducer,
    academySessionUpdateReducer,
    setHolidayReducer,
    getHolidayReducer,
    bonusSlotBookingReducer,
    academyBookingReducer,
    academyBookingByUserReducer,
    academySessionBookingReducer,
    academyUpdateProfileReducer,
    supervisorAcademyBookingReducer,
    cancleBookingReducer,
    adminnUserListReducer,
    upcomingBookingsReducer,
});