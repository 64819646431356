import sliceCreator from '../../index.js';
import { UPCOMING_BOOKINGS } from '../../../constants/index.js';
const _upcomingBookingsAction = sliceCreator('UPCOMING_BOOKINGS', UPCOMING_BOOKINGS, 'get');

const { asyncAction: upcomingBookingsAction, reducer, clearData: clearUpcomingBookingsAction } = _upcomingBookingsAction;

export default reducer;

export {
    upcomingBookingsAction,
    clearUpcomingBookingsAction
}


