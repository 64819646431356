import sliceCreator from '../../index.js';
import { CANCLE_BOOKING } from '../../../constants/index.js';
const _cancleBookingAction = sliceCreator('CANCLE_BOOKING', CANCLE_BOOKING, 'patch');

const { asyncAction: cancleBookingAction, reducer, clearData: clearCancleBookingAction } = _cancleBookingAction;

export default reducer;

export {
    cancleBookingAction,
    clearCancleBookingAction
}


