import { Typography, Box, Button,Tooltip  } from "@mui/material";
import "./index.css";
import { useNavigate } from "react-router-dom";

import HeroSection from "./HeroSection";
import HeroLeft from "./HeroLeft";
import HeroRight from "./HeroRight";

import SuperT20 from "../../assets/SuperT20.png";
import PhoneIcon from '@mui/icons-material/Phone';


function Home() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: "#000",
        flexWrap: "wrap",
        paddingBottom: { xs: "5%", sm: "2%" },
        paddingTop: "5%",
      }}
    >
      <HeroSection />
      <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
        <HeroLeft />
        <HeroRight />
      </Box>
      <Box className="imagebox" sx={{ position: "relative", overflow: "hidden" }}>
        <img src={SuperT20} alt="SuperT20" className="supert20" />
        <Box
          sx={{
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            width: { xs: "100%", sm: "90%" },
            paddingTop: "1%",
            paddingBottom: "1%",
            color: "white",
            marginTop: "1%",
            marginLeft: "0.5%",
            fontWeight: "bold",
            fontSize: { xs: "3.5vw", sm: "2vw" },
            textAlign: "center",
            paddingLeft: "2%",
            paddingRight: "2%",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
          }}
        >
          Monthly Pass Available For Selected Morning Slots at Super T-20 Stadium
        </Box>
        <Box>
          <Tooltip title="+917702737900" arrow placement="top">
            <Button
              startIcon={<PhoneIcon />}
              sx={{
                fontWeight: "bold",
                backgroundColor: "#1c1f26",
                fontSize: { xs: "4vw", sm: "1.5vw" },
                color: "#fff",
                marginTop: "2%",
                marginBottom: "2%",
                marginLeft: { xs: "32%", sm: "42%" },
                marginRight: { xs: "25%", sm: "40%" },
                paddingLeft: "2%",
                paddingTop: "1%",
                paddingBottom: "1%",
                borderRadius: "20px",
                paddingRight: "2%",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#000",
                },
              }}
              onClick={() => {
                // For mobile devices, this will open the phone dialer
                window.location.href = 'tel:+7702737900'; // Replace with your actual phone number
              }}
            >
              HELPLINE:7702737900
            </Button>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
}

export default Home;
