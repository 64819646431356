import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, RadioGroup, FormControlLabel, Radio, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminSlotsAction, patchUpdateSlotsAction, patchUpdateSurgeAmountAction, setHolidayAction, getHolidayAction } from '../../store/actions';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import toast from 'react-hot-toast';

const Slots = () => {
    const dispatch = useDispatch();
    const { successData } = useSelector((state) => state.adminSlotsReducer || []);
    const { successData: successDataSurgeAmount } = useSelector((state) => state.adminSurgeAmountReducer || []);
    const { successData: updateSlotData } = useSelector((state) => state.updateSlotReducer || []);
    const { successData: setholidayData } = useSelector((state) => state.setHolidayReducer || []);
    const { successData: getholidayData } = useSelector((state) => state.getHolidayReducer || []);

    const [slotDetails, setSlotDetails] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [open, setOpen] = useState(false);
    const [surgeAmount, setSurgeAmount] = useState(''); // State to handle surge amount input
    const [selectedDate1, setSelectedDate1] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);
    const [formattedDate1, setFormattedDate1] = useState('');
    const [formattedDate2, setFormattedDate2] = useState('');
    const [surgeConfirmOpen, setSurgeConfirmOpen] = useState(false);
    const [holidayConfirmOpen, setHolidayConfirmOpen] = useState(false);

    const [radioValue, setRadioValue] = useState('Open');

    const headers = ['Slot ID', 'Sport', 'Start Time', 'Base Price', 'Morning Academy', 'Evening Academy', 'Is Active', 'Actions'];

    const handleEditClick = (slot) => {
        setSelectedSlot(slot);
        setOpen(true);
    };
    const handleCancelSurge = () => {
        setSurgeConfirmOpen(false);
        setSurgeAmount('');
        setSelectedDate1(null);
    };
    
    const handleCancelHoliday = () => {
        setHolidayConfirmOpen(false);
        setRadioValue('');
        setSelectedDate2(null);
    };
    
    const handleCheckboxChange = (field) => {
        setSelectedSlot((prevSlot) => {
            const newSlot = { ...prevSlot };

            if (field === 'is_morning_academy') {
                newSlot.is_morning_academy = !prevSlot.is_morning_academy;
                newSlot.is_evening_academy = false;  // Only morning academy active
            } else if (field === 'is_evening_academy') {
                newSlot.is_evening_academy = !prevSlot.is_evening_academy;
                newSlot.is_morning_academy = false;  // Only evening academy active
            } else {
                newSlot[field] = !prevSlot[field];
            }

            return newSlot;
        });
    };

    const handleSave = () => {
        if (!selectedSlot) return; // Early exit if no slot is selected

        const payload = {
            is_morning_academy: selectedSlot.is_morning_academy,
            is_evening_academy: selectedSlot.is_evening_academy,
            is_active: selectedSlot.is_active,
            base_price: selectedSlot.base_price,
        };

        dispatch(patchUpdateSlotsAction({
            endPoint: `admin/slots/${selectedSlot.slot_id}`,
            updateData: payload
        }));

        setSlotDetails((prevDetails) =>
            prevDetails.map((slot) =>
                slot.slot_id === selectedSlot.slot_id
                    ? { ...slot, ...selectedSlot }
                    : slot
            )
        );
        toast.success("Slot Updated Successfully");
        setOpen(false);
    };

    useEffect(() => {
        dispatch(getAdminSlotsAction());
    }, [dispatch, updateSlotData]);

    useEffect(() => {
        if (successData) {
            // Make a shallow copy of the successData array before sorting
            const sortedData = [...successData].sort((a, b) => a.slot_id - b.slot_id);
            setSlotDetails(sortedData);
        }
    }, [successData, dispatch]);

    useEffect(() => {
        if (successDataSurgeAmount) {
            toast.success("Surge Amount Updated Successfully");
        }
        if (updateSlotData) {
            toast.success("Slot Updated Successfully");
        }
    }, [successDataSurgeAmount, updateSlotData]);


    const handleDateChange = (newDate, identifier) => {
        if (newDate && newDate instanceof Date && !isNaN(newDate)) {
            const adjustedDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000);
            const formattedDate = adjustedDate.toISOString().split('T')[0];

            if (identifier === 'picker1') {
                setSelectedDate1(newDate);
                setFormattedDate1(formattedDate);
            } else if (identifier === 'picker2') {
                setSelectedDate2(newDate);
                setFormattedDate2(formattedDate);
            }
        } else {
            // Handle invalid date
            if (identifier === 'picker1') {
                setSelectedDate1(null);
                setFormattedDate1('');
            } else if (identifier === 'picker2') {
                setSelectedDate2(null);
                setFormattedDate2('');
            }
        }
    };

    const handleSurgeAmountChange = (e) => {
        setSurgeAmount(e.target.value);
    };

    const handleAddSurgeAmount = () => {
    if (formattedDate1 && formattedDate1 !== 'Invalid date') {
        setSurgeConfirmOpen(true);
    } else {
        toast.error("Please select a valid date");
    }
};

    const handleConfirmSurge = () => {
        if (formattedDate1 && formattedDate1 !== 'Invalid date') {
            dispatch(patchUpdateSurgeAmountAction({
                endPoint: `admin/surge-amount?date=${formattedDate1}`,
                updateData: {
                    surge_amount: surgeAmount,
                },
            }))
            toast.success("Surge Amount Updated Successfully");
            setSurgeAmount('');
            setSelectedDate1(null);
            setSurgeConfirmOpen(false);
        } else {
            toast.error("Please select a valid date");
        }
    };



    const handleBasePriceChange = (basePrice) => {
        setSelectedSlot((prevSlot) => ({
            ...prevSlot,
            base_price: basePrice,
        }));
    };

    const convertTo12HourFormat = (time24) => {
        const [hours, minutes] = time24.split(':');
        const period = hours >= 12 ? 'PM' : 'AM';
        const adjustedHours = hours % 12 || 12;
        return `${adjustedHours}:${minutes} ${period}`;
    };

    const handleSubmit = () => {
        setHolidayConfirmOpen(true);
    };

    const handleConfirmHoliday = () => {
        if(radioValue === "Close"){
            dispatch(setHolidayAction({
                endPoint: `admin/set-holiday?date=${formattedDate2}`,
                updateData: {
                    is_holiday: true,
                    is_booked: true,
                }
            }));
        }else if(radioValue === "Open"){
            dispatch(setHolidayAction({
                endPoint: `admin/set-holiday?date=${formattedDate2}`,
                updateData: {
                    is_holiday: false,
                    is_booked: false,
                }
            }));
        }
        setHolidayConfirmOpen(false);
        toast.success("Holiday Status Updated Successfully");
    };

    const handleRadioChange = (event) => {
        setRadioValue(event.target.value);
    };

    useEffect(() => {
        dispatch(getHolidayAction({
            endPoint: `admin/get-holiday?date=${formattedDate2}`,
        }));
        setRadioValue('')
    }, [formattedDate2, dispatch]);

    useEffect(() => {
        if (getholidayData) {
            if (getholidayData.is_holiday || (getholidayData.is_holiday && getholidayData.is_booked)) {
                setRadioValue("Close");
            } else {
                setRadioValue("Open");
            }
        }
    }, [getholidayData]);
useEffect(() => {
    if (setholidayData) {
    }
    }, [setholidayData])

    return (
        <>
            <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '7%', marginLeft: '19%', marginRight: '1%',
                '@media (max-width: 600px)': { marginLeft: 0, padding: '1rem' }
            }}>

                <Typography variant="h4" sx={{
                    marginBottom: '2rem', fontWeight: 'bold',
                    '@media (max-width: 600px)': { fontSize: '1.5rem', textAlign: 'center' }
                }}>Base Slots</Typography>
                <Box sx={{
                    display: { xs: 'block', sm: 'none' },
                    width: '100%',
                    padding: '1rem',
                    borderRadius: '8px',
                    backgroundColor: '#f5f5f5'
                }}>
                    {slotDetails && slotDetails.length > 0 ? (
                        slotDetails.map((slot, index) => (
                            <Box key={index} sx={{
                                border: '1px solid black',
                                borderRadius: '8px',
                                marginBottom: '1rem',
                                padding: '1rem',
                                backgroundColor: '#fff'
                            }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography><strong>Slot ID:</strong> {slot.slot_id}</Typography>
                                    <Button variant="text" sx={{ color: 'black', padding: 0 }} onClick={() => handleEditClick(slot)}>
                                        <EditIcon />
                                    </Button>
                                </Box>
                                <Typography><strong>Sport:</strong> {slot.sport}</Typography>
                                <Typography><strong>Start Time:</strong> {convertTo12HourFormat(slot.start_time)}</Typography>
                                <Typography><strong>Base Price:</strong> {slot.base_price}</Typography>
                                <Typography><strong>Morning Academy:</strong> {slot.is_morning_academy ? 'true' : 'false'}</Typography>
                                <Typography><strong>Evening Academy:</strong> {slot.is_evening_academy ? 'true' : 'false'}</Typography>
                                <Typography><strong>Is Active:</strong> {slot.is_active ? 'true' : 'false'}</Typography>
                            </Box>
                        ))
                    ) : (
                        <Typography>No slots available.</Typography>
                    )}
                </Box>

                <Box sx={{ display: { xs: 'none', sm: 'block' }, width: '100%', overflowX: 'auto' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
                        <thead style={{ backgroundColor: '#31303b', color: 'white' }}>
                            <tr>
                                {headers.map((header, index) => (
                                    <th key={index} style={{ padding: '10px', border: '1px solid white' }}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {slotDetails && slotDetails.length > 0 ? (
                                slotDetails.map((slot, index) => (
                                    <tr key={index}>
                                        <td style={{ padding: '10px', border: '1px solid black', textAlign: 'center' }}>{slot.slot_id}</td>
                                        <td style={{ padding: '10px', border: '1px solid black', textAlign: 'center' }}>{slot.sport}</td>
                                        <td style={{ padding: '10px', border: '1px solid black', textAlign: 'center' }}>{convertTo12HourFormat(slot.start_time)}</td>
                                        <td style={{ padding: '10px', border: '1px solid black', textAlign: 'center' }}>{slot.base_price}</td>
                                        <td style={{ padding: '10px', border: '1px solid black', textAlign: 'center' }}>{slot.is_morning_academy ? 'true' : 'false'}</td>
                                        <td style={{ padding: '10px', border: '1px solid black', textAlign: 'center' }}>{slot.is_evening_academy ? 'true' : 'false'}</td>
                                        <td style={{ padding: '10px', border: '1px solid black', textAlign: 'center' }}>{slot.is_active ? 'true' : 'false'}</td>
                                        <td style={{ padding: '10px', border: '1px solid black', textAlign: 'center' }}>
                                            <Button variant="text" sx={{ color: 'black', padding: 0 }} onClick={() => handleEditClick(slot)}>
                                                <EditIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={headers.length} style={{ textAlign: 'center', padding: '10px', border: '1px solid black' }}>No slots available.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Box>
                {selectedSlot && (
                    <Dialog
                        open={open}
                        onClose={() => setOpen(false)}
                        sx={{
                            '& .MuiDialog-paper': { width: '600px', maxWidth: '90vw', height: '400px', maxHeight: '90vh' },
                            '@media (max-width: 600px)': { '& .MuiDialog-paper': { width: '100%', height: 'auto' } }
                        }}
                    >
                        <DialogTitle sx={{ alignSelf: 'center', fontWeight: 'bold' }}>Edit Slot Details</DialogTitle>
                        <DialogContent>
                            <Typography><b>Sport:</b> {selectedSlot.sport}</Typography><br />
                            <Typography><b>Start Time:</b> {selectedSlot.start_time}</Typography><br />

                            <TextField
                                label="Base Price"
                                variant="outlined"
                                value={selectedSlot.base_price}
                                onChange={(e) => handleBasePriceChange(e.target.value)}
                                size="small"
                            />




                            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '4px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                                    <Typography sx={{ marginRight: '1rem', fontWeight: 'bold' }}>Morning Academy:</Typography>
                                    <Checkbox
                                        checked={selectedSlot.is_morning_academy}
                                        onChange={() => handleCheckboxChange('is_morning_academy')}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                                    <Typography sx={{ marginRight: '1rem', fontWeight: 'bold' }}>Evening Academy:</Typography>
                                    <Checkbox
                                        checked={selectedSlot.is_evening_academy}
                                        onChange={() => handleCheckboxChange('is_evening_academy')}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ marginRight: '1rem', fontWeight: 'bold' }}>Is Active:</Typography>
                                    <Checkbox
                                        checked={selectedSlot.is_active}
                                        onChange={() => handleCheckboxChange('is_active')}
                                    />
                                </Box>
                            </Box>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => setOpen(false)}>Cancel</Button>
                            <Button onClick={handleSave} color="primary">Save</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, marginBottom: { xs: '10%', sm: '0%' } }}>
                <Box sx={{
                    marginLeft: { xs: '5%', sm: '20%' }, display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '2rem', marginTop: '3%', width: '40%',
                    '@media (max-width: 600px)': { width: '90%' }
                }}>
                    <Box sx={{ padding: '1rem', border: '1px solid black', borderRadius: '8px', textAlign: 'center', backgroundColor: '#f5f5f5' }}>
                        <strong>Add SURGE AMOUNT from here</strong>
                    </Box>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Select Date"
                            value={selectedDate1}
                            onChange={(newDate) => handleDateChange(newDate, 'picker1')}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                    <TextField
                        label="Enter Surge Amount"
                        variant="outlined"
                        value={surgeAmount}
                        onChange={handleSurgeAmountChange}
                        size="small"
                        disabled={!selectedDate1}
                    />

                    <Button
                        variant="contained"
                        sx={{ backgroundColor: '#31303b', color: '#fff', '&:hover': { backgroundColor: '#000' } }}
                        onClick={handleAddSurgeAmount}
                        disabled={!selectedDate1 || surgeAmount === ''}
                    >
                        Add
                    </Button>
                </Box>
                <Dialog
                    open={surgeConfirmOpen}
                    onClose={() => setSurgeConfirmOpen(false)}
                >
                    <DialogTitle>Confirm Surge Amount</DialogTitle>
                    <DialogContent>
                        <Typography fontSize={18}>
                            Are you sure you want to add a surge amount of ₹{surgeAmount} for {formattedDate1}?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelSurge} sx={{border:'2px solid #31303b'}}>Cancel</Button>
                        <Button onClick={handleConfirmSurge} color="primary" sx={{border:'2px solid #31303b'}}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <Box sx={{
                    marginLeft: '5%', marginRight: '2%', display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '2rem', marginTop: '3%', width: '40%',
                    '@media (max-width: 600px)': { width: '90%' }
                }}>
                    <Box sx={{ padding: '1rem', border: '1px solid black', borderRadius: '8px', textAlign: 'center', backgroundColor: '#f5f5f5' }}>
                        <strong>Set HOLIDAY from here</strong>
                    </Box>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Select Date"
                            value={selectedDate2}
                            onChange={(newDate) => handleDateChange(newDate, 'picker2')}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    <Box sx={{ border: '1px solid black', borderRadius: '8px', textAlign: 'center', backgroundColor: '#fff' }}>
                        <RadioGroup
                            value={radioValue}
                            onChange={handleRadioChange}
                            sx={{ display: 'flex', flexDirection: 'row', marginLeft: '35%' }}
                        >
                            <FormControlLabel value="Close" control={<Radio />} label="Close" />
                            <FormControlLabel value="Open" control={<Radio />} label="Open" />
                        </RadioGroup>
                    </Box>
                    <Button variant="contained" onClick={handleSubmit} sx={{ backgroundColor: '#31303b', color: '#fff', '&:hover': { backgroundColor: '#000' } }}>
                        Submit
                    </Button>
                </Box>
            </Box>
            <Dialog
                open={holidayConfirmOpen}
                onClose={() => setHolidayConfirmOpen(false)}
            >
                <DialogTitle>Confirm Holiday Status</DialogTitle>
                <DialogContent>
                    <Typography fontSize={18}>
                        Are you sure you want to mark {formattedDate2} as {radioValue === "Close" ? "Holiday" : "Working Day"}?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelHoliday} sx={{border:'2px solid #31303b'}}>Cancel</Button>
                    <Button onClick={handleConfirmHoliday} color="primary" sx={{border:'2px solid #31303b'}}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default Slots;
